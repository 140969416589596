<template>
  <router-layout>
    <div class="page">
      <div class="txbg" :style="{ 'background': 'url(' + userImg + ')'}"></div>
      <div class="userImg" :style="{ 'background': 'url(' + userImg + ')'}"></div>
      <div class="usrname">{{userName}}</div>
      <div class="mainBox">
        <van-cell is-link @click="toRelease">
          <template #title>
            <span class="custom-title">
              <img src="@/assets/images/user1.png" />
            </span>
            <span class="custom-title">我的发布</span>
          </template>
        </van-cell>
        <van-cell is-link @click="toCollection">
          <template #title>
            <span class="custom-title">
              <img src="@/assets/images/user2.png" />
            </span>
            <span class="custom-title">收藏</span>
          </template>
        </van-cell>
        <van-cell is-link @click="toHistory">
          <template #title>
            <span class="custom-title">
              <img src="@/assets/images/user3.png" />
            </span>
            <span class="custom-title">历史记录</span>
          </template>
        </van-cell>
      </div>
      <tabBar ref='tabbar' :tabbarActive='tabbarActive'/>
    </div>
  </router-layout>
</template>
<script>
import tabBar from "@/components/tabbar/tabBar"
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      tabbarActive:2,
      userImg:"",
      userName:""
    };
  },
  mixins: [authMixin],
  components: {
    tabBar
  },
  created(){
    this.getUserInfo()
  },
  methods: {
    toCollection(){
      this.$router.push('/Collection')
    },
    toHistory(){
      this.$router.push('/MyHistory')
    },
    toRelease(){
      this.$router.push('/Release')
    },
    getUserInfo(){
      this.$request({
        method: "get",
        url: "/cms/profile/",
      }).then(res => {
        this.userName = res.data.nickname
        this.userImg = res.data.avatar
      })
    }
  }
};
</script>
<style scoped lang="scss">
.page{
  height: 100vh;
  background: #f5f5f5;
}
.txbg {
  height: 5rem;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  filter: blur(3px);
  position: relative;
  z-index: 1;
}
.userImg {
  border-radius: 0.15rem;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  width: 3rem;
  height: 3rem;
  border: 3px solid #fff;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: -1.8rem;
}
.usrname {
  text-align: center;
  font-size: 0.42rem;
  margin-top: 0.28rem;
}
.mainBox {
  background: #fff;
  border-radius: 0.15rem;
  margin: 1rem 0.3rem 0 0.3rem;
  img {
    height: 0.6rem;
    width: auto;
    margin-right: 0.8rem;
    vertical-align: -0.2rem;
  }
  > div {
    background: transparent;
  }
}
</style>